import { useCallback } from "react";
import { Navigate, useLocation } from "react-router-dom";

interface SimpleNavigator {
    validate: () => boolean;
    defaultPath: string;
}
const DefaultRoute = (props: { element: JSX.Element }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigation: {
        [key: string]: SimpleNavigator;
    } = {
        '/login': {
            validate: useCallback(() => {
                return (JSON.parse(localStorage.getItem('currentUser') || '{}')).email ? false: true;
            }, [sessionStorage]),
            defaultPath: '/home'
        }
    };

    const defaultNavigation: SimpleNavigator = {
        validate: () => {
            return true;
        },
        defaultPath: '/login'
    };
    const pathNav = navigation[currentPath] || defaultNavigation;
    return pathNav.validate()
        ? props.element
        : <Navigate to={pathNav.defaultPath} />
}

export default DefaultRoute;