import { combineReducers } from "redux";
import authReducer from "./authReducer";
import confirmReducer from "./confirmationReducer";
import featChangeReducer from "./featureChangeReducer";
import featSelectReducer from "./featureSelectionReducer";
import markerFeatureReducer from "./markerFeatureReducer";
import notifReducer from "./notificationReducer";

const reducers = combineReducers({
    notification: notifReducer,
    selectedFeature: featSelectReducer,
    currentUser: authReducer,
    confirmation: confirmReducer,
    featureChange: featChangeReducer,
    markerFeature: markerFeatureReducer,
})

export default reducers;