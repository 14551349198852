import { startCase, uniqueId } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { Collapse, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { homebaseMap } from '../../model/config';
import { GeoFence } from '../../model/geoFence';
import './SideBar.css';

interface SideBarProps{
    cityList: any[];
    geoFences: GeoFence[];
    selectedFence: GeoFence | undefined;
    addFenceHandler: (fence: GeoFence)=> void;
    removeFenceHandler: (id: any) => void;
}

const SideBar: FC<SideBarProps> = (props) => {
    const [searchParams, setSearchParam] = useSearchParams();
    const [form, setForm] = useState({
        city: searchParams.get('city') || '',
        homebase: '',
        errorMsg: ''
    });
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [addModal, setAddModal] = useState(!!props.selectedFence);

    const featureChange = useSelector((state: any) => state.featureChange);
    const dispatch = useDispatch();
    const selectedFeature = useSelector((state: any) => state.selectedFeature);
    const addNewFence = useCallback(() => {
        if (
            form.city.length > 0 
            && form.homebase.length > 2 
            && props.geoFences.findIndex(fence => fence.homebase === form.homebase) < 0
        ){
            const newfence: GeoFence = {
                homebase: form.homebase,
                city: form.city,
                updated_by: JSON.parse(localStorage.getItem('currentUser') || '{}').email || undefined,
                geojson: {
                    type: "FeatureCollection",
                    features: []
                }
            }
            props.addFenceHandler(newfence);
            if(form.errorMsg.length > 0)
                setForm({...form, errorMsg: ""})
            setAddModal(false);
            return
        }
        setForm({...form, errorMsg: "Validation Failed!"})
    }, [form, props]);

    const handleCloseCollapse = useCallback(() => {
        if (featureChange){
            dispatch({
                type: 'popConfirmModal',
                payload: {
                    text: `There are unsaved changes. Do you want to discard changes in ${props.selectedFence?.homebase}?`,
                    callback: () => {
                        setCollapseOpen(false);
                        dispatch({
                            type: "changeCleared",
                            payload: {
                            }
                        })
                    }
                }
            })
        }
        else{
            setCollapseOpen(false);
        }
    }, [featureChange])

    useEffect(()=>{
        if (form.city.length === 0 && props.cityList && props.cityList.length > 0){
            setForm({...form, city: props.cityList[0].city})
            setSearchParam({city: props.cityList[0].city, homebase: searchParams.get('homebase') || ''})
        }
        setCollapseOpen(!!props.selectedFence);
    }, [props])

    return (
        <div className='position-relative h-100'>
            <div className='sidebar-nav border shadow-sm bg-light px-2 d-flex flex-nowrap'>
                <div className='dropdown'>
                    <div className='logo' id="nav-menu-toggler" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className='bi bi-globe2 p-2' style={{fontSize: "150%"}}></i>
                    </div>
                    <ul className='dropdown-menu' aria-labelledby='nav-menu-toggler'>
                        <li>
                            <a className="dropdown-item" onClick={()=>{
                                dispatch({
                                    type: "removeUser",
                                    payload: {}
                                })}}
                            >Logout</a>
                        </li>
                    </ul>
                </div>
                
            </div>
            <div className="my-3 px-3">
                <label htmlFor="city" className="form-label">City</label>
                <select 
                    className="form-select" 
                    id="city"
                    value={searchParams.get('city') || form.city}
                    onChange={(e)=> {
                        setSearchParam({city: e.target.value, homebase: searchParams.get('homebase') || ''});
                        setForm({...form, city: e.target.value});
                    }}
                >
                    {
                        (props.cityList || []).map((item: any, index: number) => (
                            // selected={form.city === item.city}
                            <option key={uniqueId()} value={item.city} >
                                {startCase(item.city)}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div className='sidebar-body px-3'>
                <div className='overflow-auto' style={{height: "60%"}}>
                    <div className='d-flex flex-nowrap mt-3'>
                        <h3 className=''>Fences</h3>
                        <div className='ms-auto' onClick={()=>{setAddModal(true)}}>
                            <button className='btn btn-primary'>
                                <i className='bi bi-plus-lg'></i>
                            </button>
                        </div>
                    </div>
                    
                    <table className='table table-bordered mt-3'>
                        <thead>
                            <tr>
                                <th>HomeBase</th>
                                <th>Features</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.geoFences.filter(fence => fence.city === form.city).map((fence, index) => (
                                    <tr key={uniqueId()} style={{backgroundColor: (props.selectedFence && props.selectedFence.homebase === fence.homebase)? 'azure': 'inherit'}}>
                                        <td className='d-flex flex-npwrap'>
                                            <a href='#' className="link-primary" onClick={()=>{setSearchParam({city: searchParams.get('city') || '', homebase: fence.homebase})}}>{fence.homebase}</a>
                                            <button className='btn btn-sm ms-auto' onClick={()=>{props.removeFenceHandler(fence.homebase)}}>
                                                <i className='bi bi-trash3 text-danger' ></i>
                                            </button>
                                        </td>
                                        <td>{fence.geojson.features.length}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='position-absolute h-100 bg-light start-0' style={{top: "3em", zIndex: 100}}>
                <Collapse 
                    // className='p-2'
                    // className=''
                    in={collapseOpen}
                    onExited={() => {setSearchParam({city: searchParams.get('city') || '', homebase: ''})}}
                >
                    <div className='sidebar'>
                        <div className='d-flex flex-nowrap p-2'>
                            <button className='btn' onClick={handleCloseCollapse}>
                                <i className='bi bi-arrow-left'></i>
                            </button>
                            <div className='border p-2'>
                                {props.selectedFence?.homebase}
                            </div>
                        </div>

                        <div className='p-2 pe-3 h-90 overflow-auto'>
                            <table className='table table-bordered mt-3'>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.selectedFence?.geojson.features.map((feat, index) => (
                                            <tr key={uniqueId()} style={{backgroundColor: (selectedFeature === feat.properties.sys._id)? '#ADD8E6': 'inherit'}}>
                                                <td onClick={()=>{
                                                    dispatch({
                                                        type: 'featureSelectionChange',
                                                        payload: {
                                                            featureId: feat.properties.sys._id
                                                        }
                                                    })
                                                }}>
                                                    <span className='me-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-square" viewBox="0 0 16 16">
                                                            <rect height={12} width={12} x={2} y={2} fill={feat.properties.sys._color || 'grey'}/>
                                                        </svg>
                                                    </span>
                                                    <span >
                                                        {
                                                            feat.properties.custom.zone? `Zone${feat.properties.custom.zone}`: feat.properties.sys._id
                                                        }
                                                    </span>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Collapse>
            </div>
            <Modal show={addModal} onHide={()=> {setAddModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new fence</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className='h-25 p-2' onSubmit={(e)=>{e.preventDefault(); addNewFence();}}>
                        <div className="mb-3">
                            <label htmlFor="homebase" className="form-label">Homebase</label>
                            <select 
                                className="form-select" 
                                id="homebase"
                                value={form.homebase}
                                onChange={(e)=> {
                                    setForm({...form, homebase: e.target.value});
                                }}
                            >
                                {
                                    (homebaseMap[form.city] || []).map((item: string, index: number) => (
                                        <option key={uniqueId()} value={item} >
                                            {item}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="text-warning mt-1 w-100" hidden={form.errorMsg.length === 0}>
                            {form.errorMsg}
                        </div>
                        <button type="submit" className="btn btn-primary">Add</button>
                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        </div>
    )
}

export default SideBar;