import './AddressSearch.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import _debounce from 'lodash/debounce';
import { isEmpty, toString, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { PositionData } from '../../state/reducers/markerFeatureReducer';

const AddressSearch =  () =>{
    const containerRef = useRef<HTMLDivElement>(null);
    const [searchTxt, setSearchText] = useState('');
    const [showOption, setShowOption] = useState(false);
    const [searching, setSearching] = useState(false);
    const [options, setOptions] = useState([]);
    const [placeRequest, setPlaceRequest] = useState<NodeJS.Timeout>();
    const [showLatlonInput, setShowLatlonInput] = useState(false);
    const [latlonForm, setLatlonForm] = useState<{lat: number | undefined, lon: number | undefined}>({lat: 0, lon: 0});
    const placeOptionService = new window.google.maps.places.AutocompleteService();
    const geocoder = new window.google.maps.Geocoder();
    const dispatch = useDispatch();
    const markers: PositionData[] = useSelector((state: any) => state.markerFeature);
    const fetchPredictions = _debounce(async (request) => {
        // optionals: &types=establishment&location=37.76999%2C-122.44696&radius=500
        return placeOptionService.getPlacePredictions(request, (results: any) => {
            setOptions(results || []);
            setSearching(false);
        });
    }, 1000);

    const onLatlonSelect = useCallback(() => {
        if (!latlonForm.lat || !latlonForm.lon){
            return;
        }
        const index = markers.findIndex(marker => marker.location.lat === latlonForm.lat && marker.location.lng === latlonForm.lon);
        if (index < 0){
            dispatch({
                type: 'addMarker',
                payload: {
                    data: {
                        id: uniqueId(),
                        location: {
                            lat: latlonForm.lat,
                            lng: latlonForm.lon
                        },
                        description: `lat=${toString(latlonForm.lat)}, lon=${latlonForm.lon}`
                    }
                }
            })
        }
    }, [markers, latlonForm])

    const onAutoCompleteSelect = (option: any) => {
        setSearchText(option.description);
        setSearching(true);

        geocoder.geocode(
          { placeId: option.place_id },
          async (results: any) => {
            if (isEmpty(results)) {
              return;
            }
            setSearching(false);
            setShowOption(false);
            dispatch({
                type: 'addMarker',
                payload: {
                    data: {
                        id: results[0].place_id,
                        location: {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        },
                        description: results[0].formatted_address
                    }
                }
            })
          },
        );
      };

    useEffect(() => {
        if (searchTxt.length > 2){
            setSearching(true);
            if (placeRequest){
                clearTimeout(placeRequest);
            }
            const request = { input: searchTxt };
            setPlaceRequest(setTimeout(() => {
                fetchPredictions(request)?.catch(err => console.log(err));
            }, 1000))
            
        } 
    }, [searchTxt])

    const handleWindowClick = useCallback((e: any) => {
        if (containerRef && containerRef.current && !containerRef.current.contains(e.target)){
            setShowOption(false);
        }
    }, [containerRef]);
    useEffect(()=>{
        window.addEventListener('click', handleWindowClick, true);
        return ()=> window.removeEventListener('click', handleWindowClick, true);
    }, [])
    
    return (
        <div ref={containerRef} className='dropdown'>
            <div className='form-control d-inline-flex'>
                <div className='pe-2'><i className='bi bi-search'></i></div>
                <input className='' type='text' value={searchTxt} 
                    placeholder='Search Locations'
                    onChange={(e) => setSearchText(e.target.value)} 
                    onFocus={() => setShowOption(true)}
                    style={{outline: 'none', border: 'none'}}
                />
                <div className='ps-2' style={{width: '20px'}}>
                    {
                        searching? (
                            <div className='h-100 w-100'>
                                <div className="spinner-border" role="status" style={{color: 'grey', width: '15px', height: '15px'}}>
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ): (
                            <div  onClick={()=>{setSearchText(''); setOptions([])}}>
                                {searchTxt.length > 0 && <i className='bi bi-x-lg'></i>}
                            </div>
                        )
                    }
                    
                </div>
                <div className='ps-2' onClick={() => {
                        setShowLatlonInput(!showLatlonInput)
                    }}
                    data-bs-toggle="collapse" 
                    data-bs-target="#latlonInput"
                >
                    <i className='bi bi-chevron-down' hidden={showLatlonInput}></i>
                    <i className='bi bi-chevron-up' hidden={!showLatlonInput}></i>
                </div>
            </div>
            <div className="collapse m-1" id="latlonInput">
                <div className='w-100 d-inline-flex'>
                    <div className='w-100'>
                        <div className="w-100 row p-0 m-0">
                            <label htmlFor="lat" className="col-sm-2 px-0 py-2 col-form-label text-center">Lat</label>
                            <div className="col-sm-10 p-0">
                                <input id='lat' className='form-control' 
                                    type='number' 
                                    placeholder='lat'
                                    value={latlonForm.lat}
                                    onChange={(e) => {
                                        setLatlonForm({...latlonForm, lat: e.target.value? parseFloat(e.target.value): undefined})
                                    }}
                                />
                            </div>
                        </div>
                        <div className="w-100 row p-0 m-0">
                            <label htmlFor="lon" className="col-sm-2 px-0 py-2 col-form-label text-center">Lon</label>
                            <div className="col-sm-10 p-0">
                                <input id='lon' className='form-control' 
                                    type='number' 
                                    placeholder='lon'
                                    value={latlonForm.lon}
                                    onChange={(e) => {
                                        setLatlonForm({...latlonForm, lon: e.target.value? parseFloat(e.target.value): undefined})
                                    }}
                                />
                            </div>
                        </div> 
                    </div>
                    <button className='px-1 py-3 border cursor-pointer btn btn-primary' style={{}}
                        onClick={()=>onLatlonSelect()}
                    >
                        <i className='bi bi-geo-alt' style={{fontSize: '200%'}}></i>
                    </button>
                </div>
            </div>
            <div className={`dropdown-menu p-0 w-100 ${ showOption && options.length > 0? 'show': ''}`}>
                <ul className="list-group">
                    {
                        options.map((option: any) => (
                            <li key={uniqueId()} className='list-group-item text-cut cursor-pointer'
                                onClick={() => onAutoCompleteSelect(option)}
                            >
                                {option.description}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default AddressSearch;