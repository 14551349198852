import { FC } from 'react';
import './HorizontalBreak.css';

interface HorizontalBreakProps{
    text?: string;
}

const HorizontalBreak: FC<HorizontalBreakProps> = (props) => {

    return (
        <div className='position-relative w-100'>
            <hr />
            <span className='position-absolute bg-white px-2' style={{right: "49%", top: "-10px"}} hidden={!props.text}>{props.text}</span>
        </div>
    )
}

export default HorizontalBreak;