import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import './ConfirmationModal.css';

const ConfirmationModal: FC = () => {
    const confirmation = useSelector((state: any) => state.confirmation);
    const dispatch = useDispatch();
    return (            
            <Modal show={!!confirmation}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>{confirmation?.text}</Modal.Body>
                <Modal.Footer>
                    <button 
                        type="button" 
                        className="btn btn-secondary" 
                        data-bs-dismiss="modal"
                        onClick={()=>{
                            dispatch({
                                type: 'hideConfirmModal',
                                payload: null
                            });
                        }}
                    >Cancel</button>
                    <button 
                        type="button" 
                        className="btn btn-primary"
                        onClick={() => {
                            confirmation?.callback();
                            dispatch({
                                type: 'hideConfirmModal',
                                payload: null
                            });
                        }}
                    >Confirm</button>
                </Modal.Footer>
            </Modal>
    )
}

export default ConfirmationModal;