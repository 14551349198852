import L, { LatLng, LatLngTuple } from "leaflet";
import * as _ from 'lodash';
import { uniqueId } from "lodash";
import { Marker, Polygon, Polyline, Popup } from "react-leaflet";
import { useDispatch } from "react-redux";
import PopupForm from "../../PopupForm/PopupForm";

export interface FeatureProps{
    sysId: string | number;
    properties: any;
    coord: any;
    index: any;
    deleteHandler: (id: any) => void;
    editHandler: (id: string, coordinates: LatLngTuple[]) => void;
    savePropsHandler: (properties: any[]) => void;
    color?: string | undefined;
    fillColor?: string | undefined;
    description?: string;
}

export const markerIcon = new L.Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png", //require('./marker.png'),
    iconRetinaUrl: undefined,
    iconAnchor: [12, 40],
    popupAnchor: [0, -40],
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(24, 40),
    className: ''
});

export const Fence = (props: FeatureProps) => {
    const dispatch = useDispatch();
    return <Polygon attribution={_.toString(props.sysId)} pathOptions={{color: props.color || 'grey', fillColor: props.fillColor || props.color, fillOpacity: props.fillColor? 0.5: 0.1}} key={uniqueId()} positions={props.coord}
        eventHandlers={{
            mouseover: (event) => {
                if (!event.target.pm._enabled){
                    dispatch({
                        type: 'featureSelectionChange',
                        payload: {
                            featureId: props.sysId
                        }
                    })
                } 
            },
            add: (e) => {
                e.target.on('pm:update', (e: any)=> {
                    const coordinates = e.layer._latlngs[0].map((latlng: LatLng) => Object.values(latlng).map(val => parseFloat(val)));
                    props.editHandler(_.toString(props.sysId), coordinates)
                })
            },
        }}    
    >
        <Popup maxWidth={400} autoClose={true} closeButton={false}>
            <PopupForm 
                featureId={props.index} 
                properties={props.properties}
                deleteHandler={props.deleteHandler} 
                saveFieldHandler={props.savePropsHandler}
            />
        </Popup>
    </Polygon>
};

export const Position = (props: FeatureProps) => {
    const dispatch = useDispatch();
    return (
        <Marker position={{lat: props.coord[0], lng: props.coord[1]}} icon={markerIcon}>
            {/* <Popup>
                Hello
            </Popup> */}
            <Popup minWidth={90} closeButton={false}>
                <div className="border rounded p-2">
                    <div className="p-2">
                        {props.description}
                    </div>
                    <div className="d-inline-flex w-100">
                        <button className="btn btn-warning ms-auto"
                            onClick={(e)=>{
                                e.stopPropagation();
                                dispatch({
                                    type: 'removeMarker',
                                    payload: {
                                        id: props.sysId
                                    }
                                })
                            }}
                        >Delete</button>
                    </div>
                </div>
            </Popup>
        </Marker>
    );
}

export const Line = (props: FeatureProps) => (
    <Polyline key={uniqueId()} positions={props.coord}>
        <Popup maxWidth={400}  autoClose={true} closeButton={false}>
            <PopupForm 
                featureId={props.index} 
                properties={props.properties}
                deleteHandler={props.deleteHandler} 
                saveFieldHandler={props.savePropsHandler}
            />
        </Popup>
    </Polyline>
);