export interface PositionData{
    id: string; 
    location: {lat: number, lng: number}, 
    description: string;
}

const markerFeatureReducer = (state: any[]= [], action: {type: string; payload: {data: PositionData, id: string;};}) => {
    switch(action.type){
        case "addMarker":
            return [...state, action.payload.data]
        case "removeMarker":
            return state.filter(mark => mark.id !== action.payload.id)
        default:
            return state
    }
}

export default markerFeatureReducer;