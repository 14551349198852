const featChangeReducer = (state= null, action: any) => {
    switch(action.type){
        case "changeStaged":
            return { ...(state || {}), ...action.payload }
        case "changeCleared":
                return null
        default:
            return state
    }
}

export default featChangeReducer;