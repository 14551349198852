import { FC, useCallback, useEffect, useState } from 'react';
import './PopupForm.css';

interface PropertyField{
    name: string;
    value: string;
}
interface PopupFormProps{
    featureId: any;
    properties: {[key: string]: string};
    deleteHandler: (id: string | number) => void;
    saveFieldHandler: (fields: PropertyField[]) => void;
}

const PopupForm: FC<PopupFormProps> = (props) => {

    const [fields, setFields] = useState<PropertyField[]>(
        // Object.entries(props.properties || {}).map(([key, val], index) => ({
        //     "name": key,
        //     "value": val
        // }))
        []
    );
    const handleChange = useCallback((index: number, subfield: "name" | "value", e: any)=>{
        var field;
        if (subfield == 'name'){
            field = {
                "name": e.target.value,
                "value": fields[index].value,
            }
        }else{
            field = {
                "name": fields[index].name,
                "value": e.target.value,
            }
        }
        setFields([...fields.slice(0, index), field, ...fields.slice(index+1)])
    }, [fields])

    useEffect(()=>{
        setFields([{
            name: "zone",
            value: props.properties.zone || ''
        }])
    }, [props])
    return (
        <div className='popup'>
            {/* disabled since dont need multiple props */}
            <div className='table-body'>
                <table className='table table-bordered mb-3'>
                    <tbody >
                        {
                            fields.map((field, index) => (
                                <tr key ={index}>
                                    <td>
                                        <input className='form-control popup-text' type="text" value={field.name} onChange={(e)=> {handleChange(index, "name", e)}} disabled={true}/>
                                    </td>
                                    <td>
                                        <input className='form-control popup-text' type="text" value={field.value} onChange={(e)=> {handleChange(index, "value", e)}}/>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            {/* <div className='d-flex flex-nowrap'>
                <button className='btn btn-sm btn-primary' onClick={(e)=>{e.stopPropagation(); setFields([...fields, { name: "", value: ""}]) }}>
                    <i className='bi bi-plus'></i>
                </button>
            </div> */}
            <hr/>
            <div className='d-flex flex-nowrap'>
                <button className='btn btn-sm btn-success' onClick={(e)=>{e.stopPropagation(); props.saveFieldHandler(fields) }}>
                    Save
                </button>
                <button className='btn btn-sm btn-danger ms-auto' onClick={(e)=>{e.stopPropagation(); props.deleteHandler(props.featureId) }}>
                    Delete
                </button>
                
            </div>
        </div>
    )
}

export default PopupForm;