import { useLeafletContext } from '@react-leaflet/core';
import { ControlPosition, LatLng, LatLngTuple, Layer } from 'leaflet';
import { FC, useEffect, useState } from 'react';
import './DrawControl.css';
import L from 'leaflet';
import { markerIcon } from '../EditableLayer/shapeMap';


interface DrawControlProps{
    addFeature: (e: any) => void;
    edeteFeature: (id: any, coordinates: LatLngTuple[]) => void;
}

const DrawControl: FC<DrawControlProps> = (props) => {
    const context = useLeafletContext()
    const [layerAdded, setLayerAdded]= useState(false);

    useEffect(() => {
        const map = context.map
        
        if (!layerAdded){
            console.log("draw layer added")
            setLayerAdded(true);
            (map as any).pm.addControls({  
                position: 'topleft',  
                drawCircle: false, 
                drawMarker: false,
                drawCircleMarker: false,
                drawPolyline: false,
                drawRectangle: false,
                editControls: true,
                rotateMode: false,
                cutPolygon: false,
                drawText: false,
                dragMode: false,
                removalMode: false
            }); 
            (map as any).pm.setGlobalOptions({
                allowSelfIntersection: false
            });
        }
        map.removeEventListener('pm:create');
        map.on('pm:create', (e)=> {
            map.removeLayer(e.layer);
            props.addFeature(e);
        });
    }, [props, layerAdded])

    return null
}

export default DrawControl;