import {FC, useCallback, useEffect, useMemo, useState } from 'react';
import './GeoMap.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet'
import { LatLng, LatLngExpression, LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.min';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import EditableLayer from './EditableLayer/EditableLayer';
import { Feature } from '../../model/geojson';
import * as _ from 'lodash';
import { GeoFence } from '../../model/geoFence';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import { getRandomColor } from '../../util/util-method';
import AddressSearch from '../AdressSearch/AddressSearch';
import { useSelector } from 'react-redux';
import { PositionData } from '../../state/reducers/markerFeatureReducer';
import { Position } from './EditableLayer/shapeMap';

interface GeoMapProps{
    selectedFence: GeoFence | undefined;
    onViewCity: LatLngExpression | undefined;
    onSaveFence: (id: any, bounds: Feature[]) => void;
}

const GeoMap : FC<GeoMapProps> = (props) => {
    const delhi= [28.7041, 77.1025] as LatLngExpression
    const [features, setFeatures] = useState<Feature[]>(props.selectedFence?.geojson.features || []);
    // const selectedFeature = useSelector((state: any) => state.selectedFeature);
    const [center, setCenter] = useState(delhi);
    const dispatch = useDispatch();

    const stageChanges = (type: string) => {
        dispatch({
            type: "changeStaged",
            payload: {
                type
            }
        })
    }

    const addFeature = useCallback((e: any) => {
        if (props.selectedFence){
            // console.log(e);
            var coordinates = [];
            if (e.shape.toLowerCase() === 'polygon'){
                coordinates = e.layer._latlngs[0].map((latlng: LatLng) => Object.values(latlng).map(val => parseFloat(val)))
                setCenter(coordinates[0])
            }else{
                return;
            }
            const feat: Feature = {
                type: "Feature",
                properties: {
                    sys: {
                        _id: uniqueId(),
                        _color: getRandomColor()
                    },
                    custom: {}
                },
                geometry: {
                    type: _.startCase(e.shape),
                    coordinates: coordinates
                }
            }
            setFeatures([...features, feat]);
            stageChanges("add-features");
        }else{
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: "Please select a homebase first!"
                }
            })
        }
        
    }, [features, props])

    const deleteFeature = useCallback((id: string | number) => {
        console.log(`deleting ${id}`);
        setFeatures(features.filter((feat, index) => index != id));
        stageChanges("delete-features");
    }, [features])

    const editFeature = useCallback((id: string | number, newCoordinates: LatLngTuple[]) => {
        const index = features.findIndex(feat => feat.properties.sys._id === id);
        const feat = features[index];
        feat.geometry.coordinates = newCoordinates;

        setFeatures([...features.slice(0, index), feat, ...features.slice(index+1)])
        dispatch({
            type: "addNotif",
            payload: {
                id: uniqueId(),
                text: `Feature edited: ${props.selectedFence?.homebase}`
            }
        })
        stageChanges("add-properties");
    }, [features])

    const saveFeatureProperties = useCallback((id: any, properties: any[])=> {
        const feat = features[id];
        feat.properties.custom = properties.reduce((pre, cur) => {
            if (!_.isEmpty(cur.name) && !_.startsWith(cur.name, "_")){
                pre[cur.name] = cur.value;
            }
            // pre[cur.name] = cur.value;
            return pre;
        }, {})

        setFeatures([...features.slice(0, id), feat, ...features.slice(id+1)])
        dispatch({
            type: "addNotif",
            payload: {
                id: uniqueId(),
                text: `Propeties saved: ${props.selectedFence?.homebase}`
            }
        })
        stageChanges("add-properties");
    }, [features])

    useEffect(()=> {
        setFeatures(props.selectedFence?.geojson.features || []);
        setCenter((props.selectedFence && props.selectedFence.geojson.features.length > 0 && props.selectedFence.geojson.features[0].geometry.type === 'Polygon')? (props.selectedFence.geojson.features[0].geometry.coordinates as LatLngExpression[])[0]: (props.onViewCity? props.onViewCity: delhi));
    }, [props])

    // useEffect(() => {
    //     setCenter((props.selectedFence && props.selectedFence.geojson.features.length > 0 && props.selectedFence.geojson.features[0].geometry.type === 'Polygon')? (props.selectedFence.geojson.features[0].geometry.coordinates as LatLngExpression[])[0]: (props.onViewCity? props.onViewCity: delhi));
    // }, [selectedFeature])
    
    const markers: PositionData[] = useSelector((state: any) => state.markerFeature);

    useEffect(()=>{
        if (markers.length > 0){
            setCenter(new LatLng(markers[markers.length-1].location.lat, markers[markers.length-1].location.lng));
        }
    }, [markers])
    
    // controls view params
    const ChangeView = (props: {center: LatLngExpression; zoom: number}) => {
        const map = useMap();
        map.setView(props.center, props.zoom);
        return null;
    }

    return (
        <div className='h-100 position-relative'>
            <div className='h-100'>
                <MapContainer center={center} zoom={11}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <ChangeView center={center} zoom={11}/>
                    <EditableLayer 
                        features={features} 
                        addFeature={addFeature}
                        deleteFeature={deleteFeature}
                        edeteFeature={editFeature}
                        saveFeatureProperties={saveFeatureProperties}
                    />
                </MapContainer>
            </div>
            <div className='position-absolute' style={{right: '50px', bottom: '50px', zIndex: 1000}}>
                <button 
                    className='btn btn-primary rounded-circle py-2 mb-2 d-block' 
                    onClick={() => { setCenter([...(center as LatLngTuple)]);}}
                    title="Re-center"
                >
                    <i className='bi bi-bullseye'></i>
                </button>
                {
                    props.selectedFence ? (
                        <button 
                            className='btn btn-success rounded-circle py-2 d-block' 
                            onClick={() => { props.onSaveFence(props.selectedFence?.homebase, features)}} 
                            hidden={!props.selectedFence}
                            title="Save changes"
                        >
                            <i className='bi bi-folder-plus'></i>
                        </button>
                    )
                    : null
                }
            </div>
            <div className='position-absolute border bg-light' style={{right: '20px', top: '20px', zIndex: 1000 }}>
                <AddressSearch />
            </div>
        </div>
    )
}

export default GeoMap;