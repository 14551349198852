const confirmReducer = (state= null, action: any) => {
    switch(action.type){
        case "popConfirmModal":
            return action.payload
        case "hideConfirmModal":
            return null
        default:
            return state
    }
}

export default confirmReducer;