import { API } from 'aws-amplify';
import { LatLngExpression } from 'leaflet';
import { uniqueId, toString } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GeoFence } from '../../model/geoFence';
import { Feature } from '../../model/geojson';
import GeoMap from '../GeoMap/GeoMap';
import AppNotification from '../Notification/Notification';
import SideBar from '../SideBar/SideBar';
import './Home.css'

const Home: FC = () => {
    const [searchParams, setSearchParam] = useSearchParams();
    const [geojsonQuery, setGeojsonQuery] = useState<{
        isLoading: boolean;
        data: any
    }>({ isLoading: true, data: undefined });
    const [cityConfigQuery, setCityConfigQuery] = useState<{
        isLoading: boolean;
        data: any
    }>({ isLoading: true, data: undefined });
    const [geoFences, setGeoFences] = useState<GeoFence[]>([]);
    const [selectedFence, setSelectedFence] = useState<GeoFence | undefined>(undefined);
    const [selectedCityLatlng, setSelectedCityLatlng] = useState<LatLngExpression | undefined>(undefined);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const logoutService = {
        signOut: ()=>{
            dispatch({
                type: "removeUser",
                payload: {}
            })
        }
    }

    const deleteFence = useCallback((id: any)=> {
        const currentUser = JSON.parse(localStorage.getItem('currentUserTokenInfo') || "{}")
        if(Math.trunc((new Date()).getTime()/1000) > currentUser.exp){
            logoutService.signOut();
            return;
        }

        const token = JSON.parse(localStorage.getItem('currentUserTokenInfo') || JSON.stringify({access_token: 'token123'})).access_token
        API.post('base_url', '/config/u/geofence', {
            body: {
                action: "DELETE",
                data: {
                    homebase: id
                }
            },
            headers: {
                Authorization: token
            }
        })
        .then((res) =>{
            setGeoFences(geoFences.filter(fence => fence.homebase !== id));
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: `Fence ${id} removed`
                }
            })
        })
        .catch(error => {
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: `ERROR occured in query!`
                }
            })
        })
        
    }, [geoFences])

    const addFence = useCallback((fence: GeoFence)=> {
        const currentUser = JSON.parse(localStorage.getItem('currentUserTokenInfo') || "{}")
        if(Math.trunc((new Date()).getTime()/1000) > currentUser.exp){
            logoutService.signOut();
            return;
        }

        const token = JSON.parse(localStorage.getItem('currentUserTokenInfo') || JSON.stringify({access_token: 'token123'})).access_token
        API.post('base_url', '/config/u/geofence', {
            body: {
                action: "CREATE",
                data: fence
            },
            headers: {
                Authorization: token
            }
        })
        .then((res) =>{
            setGeoFences([...geoFences, fence]);
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: `Fence ${fence.homebase} added`
                }
            })
        })
        .catch(error => {
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: `ERROR occured in query!`
                }
            })
        })
    }, [geoFences])

    const onSaveHandler = useCallback((id: any, features: Feature[]) => {
        const currentUser = JSON.parse(localStorage.getItem('currentUserTokenInfo') || "{}")
        if(Math.trunc((new Date()).getTime()/1000) > currentUser.exp){
            logoutService.signOut();
            return;
        }

        const token = JSON.parse(localStorage.getItem('currentUserTokenInfo') || JSON.stringify({access_token: 'token123'})).access_token
        API.post('base_url', `/config/u/geofence`, {
            body:{
                action: 'UPDATE',
                data: {
                    homebase: id,
                    features: features
                }
            },
            headers: {
                Authorization: token
            }
        })
        .then((res)=>{
            const index = geoFences.findIndex(fence => fence.homebase === id);
            const fence = geoFences[index];
            fence.geojson.features = features
            setGeoFences([...geoFences.slice(0, index), fence, ...geoFences.slice(index+1) ]);
            dispatch({
                type: "addNotif",
                payload: {
                id: uniqueId(),
                text: `Fence Saved: ${fence.homebase}`
                }
            });
            dispatch({
                type: "changeCleared",
                payload: {
                }
            });
        })
        .catch(error => {
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: `ERROR occured in query!`
                }
            })
        })
    }, [geoFences]);

    const selectFenceHandler = useCallback((id: any)=>{
    // console.log(`fence selected ${id}`)
    if (id){
        setSelectedFence(geoFences.find(fence => fence.homebase === id));
    }else{
        setSelectedFence(undefined);
    }
    
    }, [geoFences])

    useEffect(()=>{
        const currentUser = JSON.parse(localStorage.getItem('currentUserTokenInfo') || "{}")
        if(Math.trunc((new Date()).getTime()/1000) > currentUser.exp){
            logoutService.signOut();
            return;
        }

        const token = JSON.parse(localStorage.getItem('currentUserTokenInfo') || JSON.stringify({access_token: 'token123'})).access_token
        API.post('base_url', `/config/u/geofence`, {
            body:{
                action: 'RETRIEVE',
                data: {}
            },
            headers: { 
                Authorization: token,
            }
        })
        .then(res => 
            (res as GeoFence[]).map(fence => {
                fence.geojson.features = fence.geojson.features.map(feat => {
                    feat.geometry.coordinates = feat.geometry.coordinates.map(latlng => [parseFloat(toString(latlng[0])), parseFloat(toString(latlng[1]))]);
                    return feat;
                })
                return fence;
            })
        )
        .then((res)=>{
            setGeojsonQuery({ isLoading: false, data: res });
            setGeoFences(res);
            // console.log("geofences", res);
        })
        .catch(error => {
            dispatch({
                type: "addNotif",
                payload: {
                    id: uniqueId(),
                    text: `ERROR loading fences!`
                }
            })
        })

        API.post('base_url', `/config/u/city-list`, {body: {}, headers: {Authorization: token}})
        // .then(res => res.json())
        .then((res)=>{
            setCityConfigQuery({ isLoading: false, data: res });
        })

        return () => {};
    }, [])

    useEffect(()=>{
        if (!cityConfigQuery.isLoading){
            const city: any = (cityConfigQuery.data?.city_list || []).find((item: any) => item.city === searchParams.get('city'))
            setSelectedCityLatlng(city? [city.center_lat, city.center_lon]: undefined)
        }
        selectFenceHandler(searchParams.get('homebase'))
    }, [searchParams, cityConfigQuery, geojsonQuery])


    const currentUser = useSelector((state: any) => state.currentUser);
    useEffect(()=> {
        if (!currentUser.email){
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentUserTokenInfo');
            navigate('/login');
        }
    }, [currentUser]);

    return (
    <div className="h-100 row p-0 m-0 postion-relative">
        <div className='sidebar col-2 p-0'>
            <SideBar 
                geoFences={geoFences || []}
                selectedFence={selectedFence}
                addFenceHandler={(fence: GeoFence)=> {
                    dispatch({
                        type: 'popConfirmModal',
                        payload: {
                            text: `Do you want to add fence ${fence.homebase}?`,
                            callback: () => {addFence(fence)}
                        }
                    })
                }}
                removeFenceHandler={(id: any)=>{
                    dispatch({
                        type: 'popConfirmModal',
                        payload: {
                            text: `Do you want to remove fence with id ${id}?`,
                            callback: () => {deleteFence(id)}
                        }
                    })
                }}
                cityList={cityConfigQuery.data?.city_list || []}
            />
        </div>

        <div className='col p-0'>
            <GeoMap 
                selectedFence={selectedFence} 
                onSaveFence={(id: any, features: Feature[]) => {
                    dispatch({
                        type: 'popConfirmModal',
                        payload: {
                            text: `Do you want to update fence: ${id}?`,
                            callback: () => {onSaveHandler(id, features)}
                        }
                    })
                }}
                onViewCity={selectedCityLatlng}
            />
        </div>

        <div className='position-absolute notification' style={{ bottom: "10px", left: "10px", zIndex: 1000}}>
            <AppNotification />
        </div>

        <div 
            className="spinner-border position-absolute" 
            role="status" 
            hidden={!geojsonQuery.isLoading }
            style={{right: "50%", top: "10px", zIndex: 10001}}
        >
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
    );
}

export default Home;