import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import './Notification.css';

interface AppNotificationProps{

}

const AppNotification: FC<AppNotificationProps> = (props) => {
    const store: any[] = useSelector((state: any) => state.notification);
    const dispatch = useDispatch();
    const handleCancel = (id: any) => {
        dispatch({
            type: 'removeNotif',
            payload: {
                id: id
            }
        })
    }

    useEffect(()=>{
        if (store.length > 0){
            setTimeout(()=>{
                dispatch({
                    type: 'removeNotif',
                    payload: {
                        id: store[0].id
                    }
                })
            }, 3000)
        }  
    }, [store])
    return (
        <div className='notif-card'>
            {
                store.map((notif, index) => (
                    <div key={index} className="d-flex border shadow rounded bg-white p-2">
                        <div>
                            <i className='bi bi-check-circle-fill'></i>
                        </div>
                        <div className='mx-2'>
                            {notif.text}
                        </div>
                        <div className='ms-auto'>
                            <button className='btn btn-sm btn-danger rounded-circle' onClick={() => {handleCancel(notif.id)}}>
                                <i className='bi bi-x'></i>
                            </button>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default AppNotification;