const notifReducer = (state: any[] =[], action: any) => {
    switch(action.type){
        case "addNotif":
            return [...state, action.payload]
        case "removeNotif":
            return state.filter(notif => notif.id !== action.payload.id)
        default:
            return state
    }
}

export default notifReducer;