import { LatLngTuple } from 'leaflet';
import { uniqueId } from 'lodash';
import { FC } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { Feature } from '../../../model/geojson';
import { PositionData } from '../../../state/reducers/markerFeatureReducer';
import DrawControl from '../DrawControl/DrawControl';
import './EditableLayer.css';
import { FeatureProps, Fence, Line, Position } from './shapeMap';

interface EditableLayerProps{
    features: Feature[];
    addFeature: (e: any) => void;
    deleteFeature: (id: any) => void;
    edeteFeature: (id: any, coordinates: LatLngTuple[]) => void;
    saveFeatureProperties: (id: any, properties: any[]) => void;
}

const EditableLayer: FC<EditableLayerProps> = (props) => {
    const selectedFeature = useSelector((state: any) => state.selectedFeature);
    const markers: PositionData[] = useSelector((state: any) => state.markerFeature);
    return (
        <FeatureGroup>
            <DrawControl addFeature={props.addFeature} edeteFeature={props.edeteFeature}/>
            {
                props.features.map((feat, index) => {
                    // console.log(feat)
                    const geometryProps: FeatureProps = {
                        sysId : feat.properties.sys._id,
                        coord: feat.geometry.coordinates,
                        properties: feat.properties.custom, 
                        deleteHandler: props.deleteFeature, 
                        index,
                        savePropsHandler: (p) => {props.saveFeatureProperties(index, p)},
                        fillColor: selectedFeature === feat.properties.sys._id ? feat.properties.sys._color : undefined,
                        color: feat.properties.sys._color,
                        editHandler: props.edeteFeature
                    };
                    switch(feat.geometry.type){
                        case 'Polygon':
                            return <Fence key={uniqueId()} {...geometryProps}/>

                        case 'Marker':
                            return <Position key={uniqueId()} {...geometryProps}/>

                        case 'Polyline':
                            return <Line key={uniqueId()} {...geometryProps}/>
                    }
                })
            }
            {
                markers.map((mark, index) => {
                    return (
                        <Position key={uniqueId()}
                            sysId={mark.id}
                            coord={[mark.location.lat, mark.location.lng]}
                            properties={{}}
                            deleteHandler={()=>{}}
                            index={index}
                            savePropsHandler={()=>{}}
                            description={mark.description}
                            editHandler={()=>{}}
                        />
                )})
            }
        </FeatureGroup>
    )
}

export default EditableLayer;

