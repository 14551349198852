import './App.css';
import { Amplify } from 'aws-amplify';
import AuthGuardRoute from './route/AuthGuardRoute';
import Home from './components/Home/Home';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import DefaultRoute from './route/DefaultRoute';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';

const awsConfig = {
    // Auth: {
    //   userPoolId: process.env.REACT_APP_USERPOOLID,
    //   userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,
    //   identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    //   region: 'ap-south-1',
    //   authenticationFlowType: 'CUSTOM_AUTH',
    // },
    API: {
      endpoints: [
        {
          name: 'backend',
          endpoint: `${process.env.REACT_APP_BACKEND}.${process.env.REACT_APP_STAGE}`,
          region: 'ap-south-1',
        },
        {
          name: 'base_url',
          endpoint: process.env.REACT_APP_BASE_URL,
          region: 'ap-south-1',
        }
      ],
    },
};
  
Amplify.configure(awsConfig);

function App() {  
  return (
    <div className='h-100'>
        <BrowserRouter>
            <Routes>
                <Route path="/login"
                    element ={
                        <DefaultRoute element={<Login />} />
                    }
                />
                <Route path='/home/*' 
                    element={
                        <AuthGuardRoute
                            element={
                                <Home />
                            }
                        />
                    }
                />
                {/* <Route path="/page-not-found" element={<PageNotFound />} /> */}
                <Route path="*" element={<Navigate to="/login"/>} />
            </Routes>
        </BrowserRouter>
        <ConfirmationModal />
    </div>
  )
}

export default App;
