import { isEmpty } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import HorizontalBreak from '../common/HorizontalBreak/HorizontalBreak';
import './Login.css';

const Login: FC = () => {
    const [form, setForm] = useState({
        username: '',
        password: '',
        errorMsg: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const login = useCallback(() => {
        if(!isEmpty(form.username) && !isEmpty(form.password)){
            dispatch({
                type: "setCurrentUser",
                payload:{
                    user:{
                        username: form.username
                    }
                }
            })
            localStorage.setItem('currentUser', form.username)
            navigate("/home");
        }
        setForm({...form, errorMsg: "Invalid credentials"})
    }, [form])

    const handleCredentialResponse = (response: any) => {
        fetch('https://www.googleapis.com/oauth2/v3/userinfo?'+ new URLSearchParams({
            access_token: response.access_token
        }, )).then(response => response.json())
        .then(user => {
            dispatch({
                type: 'setCurrentUser',
                payload:{
                    user: user
                }
            })
            localStorage.setItem('currentUser', JSON.stringify(user))
            navigate("/home");
        })
        .catch((err) =>{
            setForm({...form, errorMsg: "Login Error!"})
        });

        fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?`+ new URLSearchParams({
            access_token: response.access_token
        }, ))
        .then(res => res.json())
        .then(info => {localStorage.setItem('currentUserTokenInfo', JSON.stringify({...info, access_token: response.id_token}))})
    }

    useEffect(()=>{
        const handleCodeResponse = (response: any) => {
            // console.log("credential_response", response);
            fetch('https://oauth2.googleapis.com/token?'+ new URLSearchParams({
                code: response.code,
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
                client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET || '',
                grant_type: "authorization_code",
                redirect_uri: window.location.origin
            }), { method: 'POST'})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                handleCredentialResponse(data);
            })
            .catch(err => {
                console.log(err);
            });
        };
        /* global google */
        const client = google.accounts.oauth2.initCodeClient({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            scope: 'email profile ',
            callback: handleCodeResponse
        });
        document.getElementById('google-login')?.addEventListener('click', ()=> {
            client.requestCode()
        });
      }, [])
    
    return (
        <div className='container h-100 d-flex align-items-center justify-content-center'>
            <div className='p-4 shadow w-25' style={{minWidth: "20em"}}>
                <form className='' onSubmit={(e)=>{e.preventDefault(); login()}}>
                    <h3 className='text-center'>Provide credentials</h3>
                    <fieldset disabled={true}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="username" 
                                placeholder='Enter value' 
                                aria-describedby="" 
                                value={form.username} 
                                onChange={(e)=> {setForm({...form, username: e.target.value})}}
                            />
                            {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input 
                                type="password" 
                                className="form-control" 
                                id="password" 
                                placeholder='Enter value' 
                                aria-describedby="" 
                                value={form.password} 
                                onChange={(e)=> {setForm({...form, password: e.target.value})}}
                            />
                        </div>
                        <div className="text-warning mt-1 w-100" hidden={form.errorMsg.length === 0}>
                            {form.errorMsg}
                        </div>
                        <button type="submit" className="btn btn-primary">Login</button>
                    </fieldset>
                </form>
                <HorizontalBreak text='OR'/>
                <button id='google-login' className='btn btn-primary form-control mt-3'>
                    <i className='bi bi-google'></i>
                </button>
            </div>
        </div>
    )
}

export default Login;
