import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthGuardRoute = (props: {element: JSX.Element}) => {
    const dispatch = useDispatch();
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    if (currentUser.email){
        dispatch({
            type: "setCurrentUser",
            payload: {
                user: currentUser
            }
        })
    }

    return currentUser.email? props.element : <Navigate to='/login' />
}

export default AuthGuardRoute;