const authReducer = (state= {}, action: any) => {
    switch(action.type){
        case "setCurrentUser":
            return action.payload.user
        case "removeUser":
            return {}
        default:
            return state
    }
}

export default authReducer;