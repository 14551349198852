export const homebaseMap: {
    [key: string]: string[]
} = {
    "bangalore":[
        "BLR-TD", 
        "BLR"
    ],  
    "delhi":[
        "DEL-TD", 
        "DEL"
    ],
    "indore":[
        "IND-TD", 
        "IND"
    ],
    "chennai":[
        "MAA-TD", 
        "MAA"
    ],
    "pune":[
        "PNQ-TD", 
        "PNQ"
    ],
    "mumbai":[
        "BOM-TD", 
        "BOM"
    ],
    "hyderabad":[
        "HYD-TD", 
        "HYD"
    ],
    "chandigarh":[
        "IXC-TD", 
        "IXC"
    ],
    "kolkata":[
        "CCU-TD", 
        "CCU"
    ],
    "dubai":[
        "DXB-TD", 
        "DXB"
    ],
    "abu dhabi":[
        "AUH-TD", 
        "AUH"
    ],
    "ahmedabad":[
        "AUH-TD", 
        "AUH"
    ]
}